.toggle-layout-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 19px;
  border-radius: 15px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, 
              transform 0.4s ease-in-out, 
              border 0.3s ease-in-out, 
              box-shadow 0.4s ease-in-out;
  min-width: 200px;
  z-index: 100;
  background: linear-gradient(45deg, var(--text-color) 0%, var(--text-color2) 50%, var(--text-color3) 100%);
  background-size: 300% 300%;
  color: var(--text-color);
  font-weight: bold;
  border: 1px solid var(--border-color); 
  transform-origin: center;
  will-change: transform, box-shadow;
}

.toggle-layout-button:hover {
  animation: gradientMove 5s infinite ease-in-out;
  background-size: 400% 400%;
  transform: scale(1.05);
  font-size: inherit;
  box-shadow: 0 0 5px var(--text-color), 
              0 0 10px var(--text-color2), 
              0 0 20px var(--text-color3);
}


@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}