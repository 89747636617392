.coming-soon-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    text-align: center;
    overflow: hidden;
  }
  
  .container {
    position: relative;
    margin:20px 0 70px 0;
  }
  
