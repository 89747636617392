
.language-desktop-select {
	cursor: pointer;
	display: flex;
}

.language-desktop-selected {
	position: relative;
	display: flex;
	align-items: center;
}

.language-desktop-selected-bg {
	width: 40px;
	height: 40px;
}

.language-desktop-selected-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 28px !important;
	height: 28px !important;
	z-index: 1;
}

.language-desktop-modal {
	min-height: 170px;
	position: absolute;
	right: 0;
	margin-top: 71px;
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 5px 3%;
	z-index: 50;
}

.language-desktop-modal * {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.language-desktop-selected-option-icons {
	left: 50% !important;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 28px !important;
	height: 28px !important;
	z-index: 1;
}

@keyframes rotateIcon {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.language-desktop-selected-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 27px !important;
    height: 27px !important;
    z-index: 1;
    transition: transform 0.5s ease; 
}

.language-desktop-selected-icon.rotate {
    animation: rotateIcon 0.5s ease;
}
