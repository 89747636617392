.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  background: transparent;
}

.header-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  opacity: 0; /* Start with hidden */
  transition: opacity 0.5s ease-in-out;
}

.header-squares {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%; /* Ensure it takes up the required space */
  height: 100%;
  background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 0.1vw,
      /* Smaller transparent space for denser grid */ var(--square-color) 0.1vw,
      /* Start the square */ var(--square-color) 0.2vw /* Square width */
    ),
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 0.1vw,
      /* Smaller transparent space for denser grid */ var(--square-color) 0.1vw,
      /* Start the square */ var(--square-color) 0.2vw /* Square height */
    );
  background-size: 0.2vw 0.2vw; /* Smaller squares for increased density */
  mask: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  -webkit-mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease-in-out;
  z-index: 2;
  background-color: transparent;
  pointer-events: none;
}

.squares{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%; 
  height: 100%;
  background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 0.1vw,
       var(--square-color) 0.1vw,
       var(--square-color) 0.2vw 
    ),
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 0.1vw,
       var(--square-color) 0.1vw,
       var(--square-color) 0.2vw 
    );
  background-size: 0.2vw 0.2vw;
  mask: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  -webkit-mask: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease-in-out;
  z-index: 1;
  background-color: transparent;
  pointer-events: none;
}

.header-container {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0 10px 0;
  /* border-bottom: 2px solid var(--border-color); 
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1); */
}

.logo-desktop {
  width: 30%;
  max-width: 350px;
  margin-left: 2%;
  cursor: pointer;
  z-index: 100;
}

.menu-desktop {
  margin-right: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 30%;
  z-index: 100;
}

.text-hovered {
  cursor: pointer;
  color: var(--text-color);
  background: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: currentColor;
  transition: background-size 0.3s ease-in-out,
    background-position 0.3s ease-in-out, transform 0.3s ease-in-out,
    font-weight 0.3s ease-in-out;
  position: relative; /* Ensures scaling doesn't affect surrounding layout */
  display: inline-block;
  min-width: 120px;
}

.text-hovered:hover {
  color: transparent;
  background: linear-gradient(
    to right,
    var(--text-color),
    /* Fallback to black */ var(--text-color2),
    /* Fallback to grey */ var(--text-color3) /* Fallback to light grey */
  );
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-bounce 3s ease-in-out infinite;
  transform: scale(1.1);
  transform-origin: center;
  font-weight: bold;
}

@keyframes gradient-bounce {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}

.menu-desktop-language {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (min-width: 1800px) {
  .menu-desktop {
    min-width: 30%;
  }
}

@media (max-width: 900px) {
  .menu-desktop {
    min-width: 45%;
  }
}
.settings-menu {
  height: fit-content;
  min-width: 120px;
  position: absolute;
  right: 0;
  margin-top: 100px;
  border-left: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 50;
  opacity: 0;
  transform: scale(0.9) translateY(-20px);
  visibility: hidden;
  transition: 
    opacity 0.3s ease, 
    transform 0.3s ease, 
    visibility 0.3s ease, 
    height 0.3s ease, 
    padding 0.3s ease, 
    background 0.3s ease, 
    width 0.3s ease;
    padding: var(--padding);
}

.settings-menu.scroll-trigger-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  visibility: visible;
  background: var(--background-color); /* Dynamically set background */
}

  
  .settings-menu.appear {
	animation: appear 0.3s ease-in-out forwards;
  }
  
  .settings-menu.disappear {
	animation: disappear 0.3s ease-in-out forwards;
  }
  
  @keyframes appear {
	0% {
	  opacity: 0;
	  transform: translateY(-20px);
	  visibility: visible;
	}
	100% {
	  opacity: 1;
	  transform: translateY(0);
	  visibility: visible;
	}
  }
  
  @keyframes disappear {
	0% {
	  opacity: 1;
	  transform: translateY(0);
	  visibility: visible;
	}
	100% {
	  opacity: 0;
	  transform: translateY(-20px);
	  visibility: hidden;
	}
  }
  .divider {
    background-color: rgba(0, 0, 0, 1); /* Default for light mode */
  }
  
  @media (prefers-color-scheme: dark) {
    .divider {
      background-color: rgba(255, 255, 255, 1); /* For dark mode */
    }
  }
  