/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: transparent; /* Keep it transparent initially */
}

/* Pseudo-element for Background */
.loading-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    240deg, 
    var(--gradient-dark) 0%, 
    var(--gradient-light) 50%, 
    var(--gradient-darker) 100%
  );
  opacity: 1; /* Fully visible at start */
  transition: opacity 1.5s ease-in-out; /* Smooth fade */
  z-index: -1; /* Keep it behind everything */
}

/* When animation starts, fade out the background smoothly */
.loading-overlay.fading::after {
  opacity: 0.95; /* Gradually becomes transparent */
}

/* Logo Container */
.logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  transition: transform 1.2s ease-in-out;
}

/* Move logo smoothly */
.logo-container.moved {
  transform: translate(-45.5vw, -43vh) scale(0.7);
  animation: fadeOutLogo 0.6s ease-in-out 0.7s forwards; /* Fade only at the end */
}

/* Delay the logo fade-out to happen near the end */
@keyframes fadeOutLogo {
  0% { opacity: 1; }
  80% { opacity: 0.4; }  /* Keeps visible longer */
  100% { opacity: 0; }
}

/* Smooth Animated Frame */
.logo-frame {
  position: absolute;
  width: 150%;
  height: 150%;
  border: 4px solid var(--border-color);
  opacity: 1;
  animation: drawSmoothFrame 1.5s ease-in-out forwards;
}

/* Hide the frame after animation */
.logo-frame.hidden {
  animation: fadeOutFrame 1s ease-out forwards;
}

/* Frame fade-out */
@keyframes fadeOutFrame {
  0% { opacity: 1; }
  100% { opacity: 0; transform: scale(1.1); }
}

/* Fix border color changes */
@keyframes drawSmoothFrame {
  0% { clip-path: inset(50% 50% 50% 50%); }
  25% { clip-path: inset(0 50% 100% 50%); }
  50% { clip-path: inset(0 0 100% 50%); }
  75% { clip-path: inset(0 0 0 50%); }
  100% { clip-path: inset(0); }
}
