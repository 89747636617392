.what-we-offer {
  padding: 0 0 40px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.what-we-offer-heading {
  border-top: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 50px;
  width: 95%;
  overflow: hidden;
}

/* Fade-in animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.what-we-offer-title {
  margin: 0 0 10px 5px;
  text-align: center;
  font-weight: 400;
  z-index: 10;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

/* Smooth fade-in animation */
.what-we-offer-title.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Smooth hover effect */
.what-we-offer-title.visible:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.divider-container-what-we-offer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
}

.divider-container-what-we-offer.visible {
  animation: fadeInUp 0.5s ease-out 0.5s forwards; /* Delayed fade-in */
}

.cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: 0;
  transform: translateY(20px);
  animation: none;
  position: relative; 
  height: 350px;
  
}

.cards-container.visible {
  animation: fadeInUp 0.5s ease-out 0.6s forwards; /* Delay included */
}



.card-single {
  width: 20%;
  scroll-snap-align: center;
  will-change: transform, opacity;
  z-index: 1;
  opacity: 0.2;
  transform: scale(1);
  position: absolute; 
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%) scale(0.75); 
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transition: transform 0.5s ease, opacity 0.5s ease, z-index 0.5s ease;
}

.card-single.middle.is-mobile{
  top: 17%;
  width: 20%;
  scroll-snap-align: center;
  will-change: transform, opacity;
  z-index: 100;
  opacity: 0.2;
  transform: scale(1);
  position: absolute; 
  left: 27%;
  transform: translate(-50%, -50%) scale(0.75); 
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transition: transform 0.5s ease, opacity 0.5s ease, z-index 0.5s ease;
}
.card-single.mobile-width.middle {
  width: 50%;
  transform: scale(1.2);
  opacity: 1;
}


.card-single.mobile-width.adjacent-right {
  left:76%;
  width: 30%;
  transform: scale(0.9);
  opacity: 0.8;
  transform: scale(0.9) rotateY(5deg) translateZ(-20px);
  transform: translate(-20%, -42%) scale(0.9); 
}
.card-single.mobile-width.adjacent-left{
  left:-6%;
  width: 30%;
  transform: scale(0.9);
  opacity: 0.8;
  transform: scale(0.9) rotateY(5deg) translateZ(-20px);
  transform: translate(20%, -42%) scale(0.9);
}

.card-single.middle {
  min-width: 23%;
  transform: scale(1.2) rotateY(0) translateZ(0);
  transform: translate(-0%, -50%) scale(1.2);
  z-index: 3;
  opacity: 1;
  left: 38%; 
}
.card-single.middle p
 {
  font-size: calc(100% - 50%);
}

.card-single.adjacent-left{
  left: 16.5%;
  min-width: 20%;
  transform: scale(0.9) rotateY(5deg) translateZ(-20px);
  transform: translate(5%, -50%) scale(0.9);
  z-index: 2;
  opacity: 1;
}

.card-single.adjacent-left h3,
.card-single.adjacent-right h3
 {
  font-size: calc(100% - 5%);
}
.card-single.adjacent-left:hover h3,
.card-single.adjacent-right:hover h3 {
  font-size: calc(100% + 5%); /* Increase font size by 10% */
}

.card-single.adjacent-right {
  left: 63%; 
  min-width: 20%;
  transform: scale(0.9) rotateY(5deg) translateZ(-20px);
  transform: translate(-5%, -50%) scale(0.9); 
  z-index: 2;
  opacity: 1;
}

/* Smaller outer cards */
.card-single.smallest-left{
  left: 0%;
  min-width: 17.5%;
  transform: scale(0.75) rotateY(10deg) translateZ(-40px);
  transform: translate(6%, -50%) scale(0.75);
  z-index: 1;
  opacity: 1;
}
.card-single.smallest-right {
left: 80%;
  min-width: 17.5%;
  transform: scale(0.75) rotateY(10deg) translateZ(-40px);
  transform: translate(-9%, -50%) scale(0.75);
  z-index: 1;
  opacity: 1;
}

.card-single.smallest-left h3,
.card-single.smallest-right h3
 {
  font-size: calc(100% - 15%);
}

.card-single.smallest-left:hover h3,
.card-single.smallest-right:hover h3 {
  font-size: calc(100% + 5%);
}
/* Hidden cards */
.card-single.hidden {
  display: none;
}
