.about-us-title {
  z-index: 10;
  opacity: 0;
  transform: translateY(20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.about-us-title.visible {
  animation: fadeInUp 0.5s ease-out forwards;
  animation-delay: 1s;
}

.about-us-title span {
  line-height: 150%;
  letter-spacing: 0.01em;
  text-shadow: 0.022em 0.022em 0.022em #111;
}

.who-we-are{
  z-index: 10;
  opacity: 0;
  transform: translateY(20px);
  width: 90%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;

}

.who-we-are.visible{
  animation: fadeInUp 0.5s ease-out 0.75s forwards;

}
