.App {
	text-align: center;
	margin: 0;
}

body {
font-family: "montserrat";
  margin: 0;
  min-width: 400px;
}

/*scroll*/

body::-webkit-scrollbar {
	display: none;
}

/* Firefox */
* {
	scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) #f5f4f4;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 7px;
}

*::-webkit-scrollbar-track {
	background: rgb(17, 17, 17);
	padding: 2px;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-thumb-color);
	border-radius: 2px;
	border: 0px solid #ffffff;
}

@media only screen and (max-width: 599px) {
	body {
	  min-width: 300px; 
	}
  }