.video-container {
	position: relative;
	height: 100vh;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 1);
	transition: opacity 1.5s;
	z-index: 2;
}

.overlay.fade-in {
	opacity: 1;
}

.overlay.fade-out {
	opacity: 0;
}

.divider-container{
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 0px 0;
}