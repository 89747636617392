.header-container-mobile {
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 5px 0 5px 0;
	box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	z-index: 100;
	 border-bottom: 2px solid var(--border-color); 

}

.logo-mobile {
	width: 10%;
	max-width: 150px;
	min-width: 50px;
	margin-left: 4.5%;
	cursor: pointer;
}

.menu-mobile {
	display: flex;
	align-items: center;
	margin-right: 4.5%;
	cursor: pointer;
}

.menu-mobile-modal-container {
	position: absolute;
	width: 100%;
	height: fit-content;
	z-index: 60;
	box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
	transition: 0.3s;
	z-index: 50;
}

.menu-mobile-modal-options-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.menu-mobile-modal-options-container * {
	width: 100%;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.menu-mobile-modal-link {
	height: 100px;
}

.menu-mobile-links {
	cursor: pointer;
}

.menu-mobile-modal-option {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 85%;
	margin-left: 10%;
	transition: 0.3s;
	cursor: pointer;
}

@keyframes myAnim {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.expanded-content {
	animation: myAnim 0.5s ease-in forwards;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
}

.arrow-icon {
	transition: transform 0.3s ease;
}

.arrow-icon.expanded {
	transform: rotate(180deg);
}

.language-mobile-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 85%;
	transition: 0.3s;
}

.menu-mobile-links-option-font {
	font-size: 0.8em;
}

.menu-mobile-links {
	display: flex;
	width: 83%;
	padding: 10px 0;
}

.language-mobile-selected {
	width: 55px;
	min-width: 55px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.selected-language-text {
	color: #5dcec0;
}
