.smoke-container {
	height: 100vh;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: fixed;
	overflow: hidden;
}

.smoke-canvas {
	z-index: 10;
}
.smoke-video {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
