.footer-container {
	width: 100%;
	display: flex;
	flex-direction: column;
    align-items: center;
	justify-content: space-evenly;
	padding: 8vh 0 8vh 0;
	box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.3);
	position: relative;
	bottom: 0;
	z-index: 80;
	border-top: 1px solid var(--border-color); 

}

.footer-logo{
    width: 70px;

}

.footer-heading{
    transition: 0.3s;

}