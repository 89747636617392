.mode-container {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.icon-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.background-icon {
  border-radius: 50%;
  border: 1px solid var(--border-color);
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.background-icon svg {
  width: 30px;
  height: 30px;
}

.settings-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-self: flex-start;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.settings-menu-container {
  min-height: 35px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 55;
}

.language-dropdown-container {
  position: relative;
}

.language-dropdown {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transform: scaleY(0.9);
  transition: max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
}

.language-dropdown.appear {
  max-height: 200px; /* Adjust based on dropdown content */
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

.language-dropdown.disappear {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0.9);
}

.language-dropdown-container svg {
  display: inline-block;
}
.language-dropdown-container svg.rotate {
  animation: rotateAnimation 0.3s ease-in-out;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dropdown-text-hovered-const {
  transform: scale(1.1);
  transition: transform 0.2s ease;
}
.dropdown-text-hovered:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease;
}
