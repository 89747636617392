
.card-outline {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	transition: transform 0.6s ease, opacity 0.6s ease, z-index 0.6s ease;
	min-height: 200px;
	transform-origin: center;
	position: absolute;
	border-radius: 8px;
	border: 1px solid var(--border-color);
	gap:5px;
  }
  
  .card-outline:hover {
	box-shadow: 0 4px 15px var(--box-shadow-color);
	cursor: pointer;
	transition: box-shadow 0.5s ease-in-out, transform 0.2s ease-in-out;
  }
  .card-single.middle .card-outline {
	box-shadow: 0 2px 10px var(--box-shadow-color);
  }
  .card-background {
	transition: opacity 0.5s ease, transform 0.5s ease;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	will-change: opacity, transform;
  }
  
  .card-container:hover .card-background {
	opacity: 0.4;
  }
  
  .card-icon {
	transition: transform 0.4s ease;
  }
  
  .card-icon:hover {
	transform: scale(1.1);
  }
  
  .card-title {
	margin: 10px 0 0 0;
	width: 100%;
	transition: color 0.3s ease;
  }
  
  .card-description {

	width: 100%;
	transition: opacity 0.4s ease, transform 0.4s ease;
	position: absolute;
  }
  
  .card-container:hover .card-description {
	opacity: 1;
  }
  
  .card-text-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: flex-start;
  }
  