.video-text-container {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  height: 100vh;
}

.text-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.video-text-container span {
  opacity: 0;
  display: inline-block;
  animation: animate 0.35s ease-out forwards;
  text-shadow: 0.022em 0.022em 0.022em #111;
  letter-spacing: 0.01em;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg);
    filter: blur(0px);
  }
}

.button-container {
  opacity: 0;
  transform: translateY(20px);
  animation: none;
}

.button-container.visible {
  animation: fadeInUp 0.5s ease-out 1.5s forwards; /* Button appears after text animation */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .video-text-container span {
    font-size: calc(2em + 1.2vw);
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  .video-text-container span {
    font-size: calc(2.4em + 1.8vw);
  }
}

@media (min-width: 801px) and (max-width: 1200px) {
  .video-text-container span {
    font-size: calc(2.8em + 2vw);
  }
}

@media (min-width: 1200px) {
  .video-text-container span {
    font-size: calc(3em + 2.5vw);
  }
}
