.rotating-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.pattern-container {
    contain: strict;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(
        circle,
        var(--square-color) 0%,
        var(--square-color) 10%,
        transparent 70%
      ),
      repeating-linear-gradient(
        90deg,
        transparent,
        transparent 0.2vw,
        var(--square-color) 0.2vw,
        var(--square-color) 0.4vw
      ),
      repeating-linear-gradient(
        0deg,
        transparent,
        transparent 0.2vw,
        var(--square-color) 0.2vw,
        var(--square-color) 0.4vw
      );
    background-size: 0.4vw 0.4vw, 100% 100%, 100% 100%;
    background-blend-mode: overlay;
    opacity: 0.9;
  }
  
  
.svg-design {
  width: 250px;
  height: 250px;
}

/* Pulsating Glow Effect */
.glowing-circle {
    animation: sunEruption 2s infinite alternate cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: r, opacity, transform;
  isolation: isolate;
  transform: translateZ(0);
}

/* Keyframes for Sun-Like Glow Effect */
@keyframes sunEruption {
  from {
    r: 100; /* Base size */
    opacity: 1.1; /* Dim glow */
  }
  to {
    r: 80; /* Return to base size */
    opacity: 0.95; /* Dim glow */
  }
}

/* Hollow Inner Circle */
.inner-circle {
  transition: transform 0.3s ease-in-out;
}

/* Outer Segments */
.outer-segments {
  transform-origin: 200px 200px; /* Center point for rotation */
  transition: transform 0.3s ease-in-out;
}

/* Hover Effect: Rotate Outer Segments */
.svg-design:hover .outer-segments {
  animation: rotate 20s linear infinite;
}

/* Rotation Animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.arc-group {
  animation: rotateCircle 20s linear infinite; /* Rotation animation */
}

@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
}

.rotating-arcs-svg {
  width: 400px;
  height: 400px;
  position: absolute;
  animation: rotate-arcs 40s linear infinite;
  pointer-events: none;
}

@keyframes rotate-arcs {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.thick-arc,
.small-arc {
  transform-origin: 200px 200px;
}

